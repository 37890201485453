:root {
  --primary-color: #6d28d9;
  --table-header-bg: #683fe9;
  --table-header-color: #ffffff;
  --table-odd-row-bg: #e6e3ff;
  --table-even-row-bg: #ffffff;
  --phone-input-height: 47px;
  --phone-input-font-size: 16px;
  --react-select-height: 53px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}

/* These styles make the root element full-height */
#root {
  display: flex;
  height: 100%;
}

.sbContentHolder-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.3);
}

.PhoneInputInput {
  height: var(--phone-input-height) !important;
  margin-right: 5px;
  font-size: var(--phone-input-font-size);
  font-weight: medium;
  border: none;
  outline: none;
}

.select__control {
  border: none !important;
  outline: none !important;
  min-height: var(--react-select-height) !important;
  margin-right: 2px;
}

.select__control--is-focused {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.rc-table table {
  border-collapse: collapse;
}
.rc-table-thead {
  /* border-top: 1px solid #d5d5d5; */
  border-bottom: 1px solid #d5d5d5;
}
.rc-table-thead .rc-table-cell {
  background-color: var(--table-header-bg);
  color: var(--table-header-color) !important;
}
.rc-table-thead .rc-table-cell:nth-child(1){
  border-top-left-radius: 10px !important;

}
.rc-table-thead .rc-table-cell:last-child{  
  border-top-right-radius: 10px !important;

}

.rc-table-tbody .rc-table-row {
  background-color: var(--table-odd-row-bg);
}

.rc-table-tbody .rc-table-row:nth-of-type(even) {
  background-color: var(--table-even-row-bg);
}

.rc-table-tbody .rc-table-row:last-child {
  border-bottom-left-radius: 10px !important;

}

.rc-table-thead .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-tbody .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-tbody .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-cell {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
}

.rc-pagination-item-active {
  border-color: var(--primary-color) !important;
}

.rc-pagination-item-active a {
  color: var(--primary-color) !important;
}

.Dropdown-control {
  width: 65px !important;
}
/* .r-backgroundColor-1niwhzg{
  background-color: white !important;
} */
.css-1nmdiq5-menu{
  z-index: -1000 !important;
}
tr:last-child td:first-child { border-bottom-left-radius: 10px; border-bottom: 0px solid black !important; }
tr:last-child td:last-child { border-bottom-right-radius: 10px; border-bottom: 0px solid black !important;}